import React, { useState } from 'react';
import { makeStyles, createStyles, useTheme } from '@material-ui/core/styles';
import { Theme, Card, CardContent, CardActions, Typography, Button } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';

import { LocationManager } from 'models';
import { toReadableAge, toReadableDistance, convertToDD } from 'utils';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      minWidth: 320,
      marginBottom: theme.spacing(2),
    },
    link: {
      color: theme.palette.text.link,
      textDecoration: 'underline',
      display: 'inline-flex',
      marginLeft: theme.spacing(2),
      fontSize: 'larger',
      fontWeight: 'bold',
    },
    icon: {
      marginRight: theme.spacing(1),
      color: theme.palette.text.link,
    },
    headerContainer: {
      display: 'flex',
      padding: theme.spacing(2),
      paddingBottom: theme.spacing(1),
      alignItems: 'center',
    },
    headerContent: {
      flex: '1 1 auto',
    },
    headerText: {
      fontWeight: 'bold',
    },
    distanceText: {
      float: 'right',
    },
    button: {
      width: '90%',
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
      marginBottom: theme.spacing(1),
    },
  }),
);

type GetButtonText = {
  (hasObservation: boolean, submitted: boolean, validated: boolean, disabled: boolean): string;
};

const getButtonText: GetButtonText = (hasObservation, submitted, validated, disabled) => {
  if (hasObservation && submitted && !validated) {
    return 'Edit current observation';
  }

  if (hasObservation && disabled) {
    return 'Observation validated';
  }

  return 'Add a new observation';
};

interface CardProps {
  onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  display?: 'block' | 'inline-block';
  style?: React.CSSProperties;
}

/**
 * Location Card will accept all Location parameters, however state will be optional as it's not used
 */
type LocationCardProps = (CardProps & Partial<LocationManager.Location>) &
  Omit<LocationManager.Location, 'validatorId' | 'newObservation'>;

const LocationCard: React.FunctionComponent<LocationCardProps> = ({
  name,
  distance,
  lat,
  lon,
  age,
  id,
  highlight,
  display,
  observations,
  onClick,
  style,
}) => {
  const classes = useStyles();
  const theme = useTheme();

  const [hover, setHover] = useState(false);

  const hasObservation = observations[0] != null;

  const { submitted, validated, validatedToday } = observations[0] ?? {
    submitted: false,
    validated: false,
    validatedToday: false,
  };

  const disabled = validatedToday;

  let bgColor = theme.palette.common.white;
  if (hover) bgColor = theme.palette.common.neutralXLight;
  if (highlight) bgColor = theme.palette.common.neutralLight;
  if (disabled) bgColor = theme.palette.common.neutralLight;

  return (
    <Card
      className={classes.root}
      variant="outlined"
      style={{
        backgroundColor: bgColor,
        display: display || 'block',
        ...style,
      }}
      onClick={onClick}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      onTouchStart={() => setHover(true)}
      onTouchEnd={() => setHover(false)}
    >
      <div className={classes.headerContainer}>
        <div className={classes.headerContent}>
          <Typography component="span" variant="h5" color="textPrimary" className={classes.headerText}>
            {name}
          </Typography>
          <Typography component="span" variant="subtitle2" color="textSecondary" className={classes.distanceText}>
            {toReadableDistance(distance)}
          </Typography>
        </div>
      </div>
      <CardContent style={{ paddingTop: '0px' }}>
        {convertToDD(lat, true)} {convertToDD(lon, false)}
      </CardContent>
      <CardContent style={{ paddingTop: '0px' }}>
        Observed {toReadableAge(age == null ? null : age) ?? 'never'}
      </CardContent>
      <CardActions style={{ justifyContent: 'center' }}>
        <Button
          className={classes.button}
          variant="contained"
          disabled={disabled}
          component={RouterLink}
          to={`/observer/location/${id}`}
        >
          {getButtonText(hasObservation, submitted, validated, disabled)}
        </Button>
      </CardActions>
    </Card>
  );
};

LocationCard.displayName = 'LocationCard';
export default LocationCard;
