import React, { useEffect, useState } from 'react';

import { useParams, Link, Routes, Route, useNavigate } from 'react-router-dom';
import { makeStyles, createStyles, useTheme } from '@material-ui/core/styles';
import { Theme, Button, Typography, Container, CircularProgress } from '@material-ui/core';

import {
  ButtonLikeRadioInput,
  ProgressNavList,
  ErrorSegment,
  CustomDateTimePicker,
  HelpTypography,
  PrettierTextArea,
  LoadableImage,
  AreYouSureDialog,
  Note,
} from 'components';
import { toTimeDDMonYearFormat } from 'utils';
import { messageWhenBadRequest } from 'utils/error';

import { useAppSelector, useAppDispatch } from 'hooks';
import { ObserverActions } from 'state/observer';
import { ToastActions } from 'state/toast';
import { ToastType } from 'models/toast';
import CreateDataEntrySteps from './CreateDataEntrySteps';

const panelWidth = 400;
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: 'relative',
      height: '100%',
      paddingLeft: '0px',
      paddingRight: '0px',
      // background: theme.palette.common.white,
    },
    leftPanel: {
      width: panelWidth,
      position: 'absolute',
      left: 0,
      bottom: 0,
      top: 0,
    },
    leftContent: {
      height: '100%',
      overflow: 'auto',
      padding: 25,
    },
    rightPanel: {
      position: 'absolute',
      left: panelWidth,
      right: 0,
      bottom: 0,
      top: 0,
      height: '100%',
    },
    title: {
      fontWeight: 'bold',
      marginTop: theme.spacing(5),
      marginBottom: theme.spacing(1),
      color: theme.palette.text.primary,
      width: 'fit-content',
    },
    subtitle: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(4),
      color: theme.palette.text.secondary,
    },
    reviewSectionText: {
      fontWeight: 'bold',
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(1),
      color: theme.palette.text.secondary,
    },
    errorText: {
      padding: theme.spacing(1),
      color: theme.palette.text.primary,
      width: '100%',
    },
    buttonLight: {
      height: '50px',
      backgroundColor: theme.palette.common.neutralLight,
      color: theme.palette.common.neutralDark,
      marginBottom: theme.spacing(1),
      marginTop: '5px',
      '&:hover': {
        backgroundColor: theme.palette.common.neutralLight,
      },
      fontWeight: 'bold',
    },
    button: {
      height: '50px',
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
      marginBottom: theme.spacing(1),
      marginTop: '5px',
      '&:hover': {
        backgroundColor: theme.palette.common.black,
      },
      fontWeight: 'bold',
      paddingLeft: '0px',
      paddingRight: '0px',
    },
    lowerSegment: {
      width: '100%',
      height: '75px',
    },
    stepsContainer: {
      display: 'grid',
      gridTemplateRows: '20% auto 10%',
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(2),
      paddingLeft: '0px',
      paddingRight: '0px',
      maxWidth: '100%',
    },
    container: {
      display: 'grid',
      gridTemplateRows: 'auto 10%',
      overflow: 'hidden',
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(2),
    },
    progress: {
      backgroundColor: theme.palette.common.neutralLight,
    },
    progressBar: {
      backgroundColor: theme.palette.common.neutralDark,
    },
    noteSubtitle: {
      color: theme.palette.common.neutralDark,
      fontWeight: 'bold',
    },
    note: {
      width: '100%',
      margin: theme.spacing(1),
    },
    stepIndicator: {
      marginBottom: theme.spacing(2),
      marginTop: theme.spacing(2),
      fontSize: 18,
      color: theme.palette.common.neutralDark,
    },
  }),
);

const FSEDataEntryDesktop: React.FunctionComponent<Record<string, unknown>> = () => {
  const classes = useStyles();
  const theme = useTheme();
  const navigate = useNavigate();
  const { locationId: locationIdRaw } = useParams<{ locationId: string }>();
  const locationId = locationIdRaw ?? '';
  const [confirmOpen, setConfirmOpen] = useState(false);
  const fuelLoadState = useState(false);
  const [submitting, setSubmitting] = useState('');
  const [hasSetTime, setHasSetTime] = useState(false);

  const openHelp = () => {
    /* no op */
  };

  // handle getting the stepId if it exists and setting it to 0 if it doesn't
  // useParams won't recognise it as it's not explicitly set, but doing so gets
  // rid of the review page.
  const stepIdMatch = window.location.pathname.match(/step\/([0-9]*)[/]?/);
  const stepIdStr = stepIdMatch == null ? null : stepIdMatch[1];
  const stepId = stepIdStr == null || Number.isNaN(parseInt(stepIdStr, 10)) ? 0 : parseInt(stepIdStr, 10);

  const { observer, auth } = useAppSelector((state) => state);
  const { locations, upload } = observer;
  const location = locations.object && locations.object.find((loc) => loc.id === parseInt(locationId, 10));
  const newObservation = location?.newObservation.object != null ? location.newObservation.object : {};

  // Fix for bug AF-948
  // A minimum date (inclusive) in order to prevent the user from backdating an
  // observation before the previous observation which will cause an observation
  // to be stuck in the submitted state and never be visible for validation
  const prevValidatedObs = location?.observations.find((obs) => obs.validated);
  const minObsBackdate = prevValidatedObs ? new Date(prevValidatedObs.time) : undefined;
  // The timedate picker doesn't seem to enforce minimum time, so just forcing the
  // new observation to be the next day.
  minObsBackdate?.setUTCHours(0, 0, 0, 0);
  minObsBackdate?.setUTCDate(minObsBackdate.getUTCDate() + 1);

  const dispatch = useAppDispatch();

  const steps = CreateDataEntrySteps(newObservation, location, dispatch, openHelp, fuelLoadState);

  const isReview = window.location.pathname.indexOf('review') > -1;
  useEffect(() => {
    if ((stepIdStr == null && !isReview) || stepId >= steps.length) {
      navigate(`/observer/location/${locationId}/step/0`, {
        replace: true,
      });
    }
    // If it's the review page check to make sure required options are completed
    // if not, then redirect to that step
    // find Index that is required and not complete
    const incompleteStep = steps.findIndex((step) => !step.optional && !step.completed);
    if (isReview && incompleteStep > -1) {
      navigate(`/observer/location/${locationId}/step/${incompleteStep}`, {
        replace: true,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history, locationId, stepId, stepIdStr]);

  useEffect(() => {
    if (locations.status === 'idle' && auth.status === 'finished') dispatch(ObserverActions.getLocations());
  }, [dispatch, locations.status, auth.status]);

  useEffect(() => {
    if (
      (submitting === 'add' && upload.status === 'finished') ||
      (submitting === 'edit' && location?.newObservation.status === 'finished')
    ) {
      ToastActions.showToast({
        type: ToastType.success,
        message: `You have successfully submitted the observation for ${location?.name}`,
      });
      setSubmitting('');
      navigate(`/observer/locations/list`);
    }
    if (upload.status === 'error') {
      ToastActions.showToast({
        type: ToastType.error,
        message: 'Error uploading observation',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history, upload.status, location?.newObservation.status]);

  const submitObservation = async () => {
    if (location) {
      if (location.observations[0]?.submitted) {
        dispatch(
          ObserverActions.editObservation({ location, id: location.observations[0].id, update_observer: true }),
        ).then(() => dispatch(ObserverActions.getLocations()));
        setSubmitting('edit');
      } else {
        dispatch(ObserverActions.addObservation({ location })).then(() => dispatch(ObserverActions.getLocations()));
        setSubmitting('add');
      }
    }
  };

  if (locations.status === 'idle' || locations.status === 'loading')
    return (
      <div style={{ display: 'flex', justifyContent: 'center', marginTop: '8px' }}>
        <CircularProgress style={{ margin: theme.spacing(2) }} aria-valuetext="loading" />
      </div>
    );

  if (locations.status === 'error' && locations.error)
    return (
      <ErrorSegment
        style={{ marginTop: theme.spacing(4) }}
        error={locations.error}
        text="Failed To load Locations"
        onRetry={() => dispatch(ObserverActions.getLocations())}
      />
    );

  if (location == null && locations.status === 'finished')
    return (
      <Typography variant="h4" align="center" className={classes.errorText}>
        Error 404: Page Not Found
      </Typography>
    );

  if (location == null) return <></>;

  const step = steps[stepId];

  return (
    <div className={classes.root}>
      <div className={classes.leftPanel}>
        <div className={classes.leftContent}>
          <Typography className={classes.stepIndicator}>
            Step {stepId + 1} of {steps.length}
          </Typography>
          <ProgressNavList
            steps={steps}
            onChange={(value) => navigate(`/observer/location/${locationId}/step/${value}`)}
          />
        </div>
      </div>
      <div className={classes.rightPanel}>
        <div style={{ height: '100%', display: 'grid' }}>
          <Routes>
            <Route
              path="step/*"
              element={
                <div
                  style={{
                    paddingTop: theme.spacing(1),
                    paddingBottom: theme.spacing(2),
                    paddingLeft: '24px',
                    paddingRight: '24px',
                  }}
                >
                  {step != null ? (
                    <>
                      <div>
                        <HelpTypography
                          variant="h4"
                          helpId={step.helpId}
                          onTriggerHelp={openHelp}
                          className={classes.title}
                          tooltip
                        >
                          {step.heading}
                        </HelpTypography>

                        <Typography variant="subtitle1" className={classes.subtitle}>
                          {step.label}
                        </Typography>
                      </div>

                      <div
                        style={{
                          display: 'grid',
                          alignItems: 'start',
                          justifyItems: 'center',
                          gridTemplateRows: 'auto 1fr',
                          // marginBottom: theme.spacing(5),
                        }}
                      >
                        {step.content}
                      </div>
                    </>
                  ) : (
                    <Typography variant="h4" className={classes.title}>
                      Invalid Step
                    </Typography>
                  )}
                  <Container className={classes.stepsContainer}>
                    {stepId < steps.length - 1 ? (
                      <Button
                        className={classes.button}
                        fullWidth
                        component={Link}
                        to={`/observer/location/${locationId}/step/${stepId + 1}`}
                      >
                        {`Next : ${steps[stepId + 1].heading}`}
                      </Button>
                    ) : (
                      <Button
                        className={step?.optional && !step?.completed ? classes.buttonLight : classes.button}
                        fullWidth
                        component={Link}
                        onClick={() => {
                          if (!hasSetTime) {
                            dispatch(
                              ObserverActions.setNewObservation({
                                id: location.id,
                                current: {
                                  time: Date.now(),
                                },
                              }),
                            );
                            setHasSetTime(true);
                          }
                        }}
                        to={`/observer/location/${locationId}/review`}
                      >
                        Next : Observation Summary
                      </Button>
                    )}
                  </Container>
                </div>
              }
            />

            <Route
              path="review"
              element={
                <Container className={classes.container}>
                  <div style={{ overflowY: 'auto', overflowX: 'hidden' }}>
                    <Typography variant="h4" className={classes.title}>
                      {location.name}
                    </Typography>

                    <div style={{ display: 'flex' }}>
                      <Typography variant="subtitle1" className={classes.subtitle}>
                        {newObservation.time && toTimeDDMonYearFormat(new Date(newObservation.time))}
                      </Typography>

                      <CustomDateTimePicker
                        className={classes.subtitle}
                        style={{ textDecoration: 'underline', cursor: 'pointer', marginLeft: theme.spacing(2) }}
                        onChange={(date) =>
                          dispatch(
                            ObserverActions.setNewObservation({
                              id: location.id,
                              current: {
                                time: date.getTime(),
                              },
                            }),
                          )
                        }
                        text="Change date and time"
                        minDate={minObsBackdate}
                        minDateMessage="You cannot backdate an observation before the last validated observation"
                      />
                    </div>

                    <Typography variant="subtitle1" className={classes.reviewSectionText}>
                      Photo
                    </Typography>

                    {newObservation.image ||
                    (location.observations[0] &&
                      location.observations[0]?.submitted &&
                      location.observations[0]?.imageUrl) ? (
                      <div style={{ margin: `${theme.spacing(1)}px ${theme.spacing(2)}px` }}>
                        <LoadableImage
                          src={
                            newObservation.image ??
                            (location.observations[0] && location.observations[0].submitted
                              ? location.observations[0].imageUrl
                              : undefined)
                          }
                        />
                      </div>
                    ) : (
                      <Typography variant="subtitle2" className={classes.note}>
                        No photo available
                      </Typography>
                    )}

                    <Typography variant="subtitle1" className={classes.reviewSectionText}>
                      Confidence level
                    </Typography>

                    <ButtonLikeRadioInput
                      options={[
                        { text: 'Confident', value: 1 },
                        { text: 'Not confident', value: 0 },
                      ]}
                      initialValue={newObservation.confident ?? location.observations[0]?.confident ? 1 : 0}
                      onChange={(value) =>
                        dispatch(
                          ObserverActions.setNewObservation({
                            id: location.id,
                            current: {
                              confident: value === 1,
                            },
                          }),
                        )
                      }
                      style={{ width: '100%' }}
                    />

                    <Typography variant="subtitle1" className={classes.reviewSectionText}>
                      Notes
                    </Typography>

                    {(!location?.observations[0]?.validated &&
                      location?.observations[0]?.notes.map((note, idx) => (
                        <Note key={note.id || `idx-${idx}`} note={note} />
                      ))) || (
                      <Typography variant="subtitle2" className={classes.note}>
                        No notes available
                      </Typography>
                    )}

                    <Typography variant="subtitle1" className={classes.reviewSectionText}>
                      Additional Note
                    </Typography>

                    <PrettierTextArea
                      paperStyle={{
                        marginBottom: theme.spacing(2),
                      }}
                      minRows={8}
                      value={newObservation.newNote?.note || ''}
                      onChange={(event) =>
                        dispatch(
                          ObserverActions.setNewObservation({
                            id: location.id,
                            current: {
                              newNote: { note: event.target.value, id: 0, modified: new Date().toISOString() },
                            },
                          }),
                        )
                      }
                    />

                    {upload.status === 'error' && upload.error && (
                      <ErrorSegment text={messageWhenBadRequest(upload.error)} error={upload.error} hideRetry />
                    )}
                  </div>

                  <Button
                    className={classes.button}
                    disabled={upload.status === 'loading'}
                    fullWidth
                    onClick={() => setConfirmOpen(true)}
                  >
                    Submit Observation
                    {upload.status === 'loading' && (
                      <CircularProgress size={16} style={{ marginLeft: theme.spacing(1) }} aria-valuetext="loading" />
                    )}
                  </Button>
                </Container>
              }
            />
          </Routes>

          <AreYouSureDialog
            open={confirmOpen}
            onConfirm={() => {
              setConfirmOpen(false);
              submitObservation();
            }}
            onClose={() => setConfirmOpen(false)}
          />
        </div>
      </div>
    </div>
  );
};

export default FSEDataEntryDesktop;
