import React from 'react';

import { LocationManager, curingCarouselSlides, fuelConditionCarouselSlides } from 'models';
import { isValidFuelLoad, getFullName } from 'utils';
import EditFieldDialog from './validator/EditFieldDialog';

export type ClickableFields = 'curing' | 'fuelLoad' | 'fuelCondition';
export type EditFieldDialogParams = Omit<Parameters<typeof EditFieldDialog>[0], 'open' | 'onClose'>;

export const openEditFieldDialog = ({
  option,
  location,
  setModalSettings,
  setModalOpen,
}: {
  option: ClickableFields;
  location: LocationManager.Location;
  setModalSettings: React.Dispatch<React.SetStateAction<EditFieldDialogParams | null>>;
  setModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  // It always grabs [0] but it this always going to be the validated one or the submitted one?
  // Maybe its better to do some explicit check here.
  const observation = location.observations[0];
  const observerName = getFullName(observation.observer);
  switch (option) {
    case 'curing':
      setModalSettings({
        locationId: location.id,
        fieldTitle: 'Grass Curing',
        field: 'curing',
        observationId: observation.id,
        options: curingCarouselSlides,
        currentValue: observation.curing ?? null,
        currentValueText: `${observation.curing ?? null}${observation.curing != null ? '%' : ''}`,
        currentValueTextPrevious: `${observation.curingPrevious ?? null}${
          observation.curingPrevious != null ? '%' : ''
        }`,
        prevValueText: `${observation.curingPrevious ?? null}${observation.curingPrevious != null ? '%' : ''}`,
        prevValueTime: observation?.validationTime,
        observerName,
      });
      setModalOpen(true);
      break;
    case 'fuelLoad':
      setModalSettings({
        locationId: location.id,
        fieldTitle: 'Fuel Load',
        field: 'fuelLoad',
        observationId: observation.id,
        currentValue: observation.fuelLoad ?? null,
        currentValueText: `${observation.fuelLoad ?? null}${observation.fuelLoad != null ? ' t/ha' : ''}`,
        currentValueTextPrevious: `${observation.fuelLoadPrevious ?? null}${
          observation.fuelLoadPrevious != null ? ' t/ha' : ''
        }`,
        prevValueText: `${observation.fuelLoadPrevious ?? null}${observation.fuelLoadPrevious != null ? 't/ha' : ''}`,
        prevValueTime: observation?.validationTime,
        observerName,
        units: ' t/ha',
        isValid: isValidFuelLoad,
        helpText: 'Must be a valid number between 1 and 12',
      });
      setModalOpen(true);
      break;
    case 'fuelCondition':
      setModalSettings({
        locationId: location.id,
        fieldTitle: 'Fuel Condition',
        field: 'fuelCondition',
        observationId: observation.id,
        options: fuelConditionCarouselSlides,
        currentValue: observation.fuelCondition ?? null,
        currentValueText: LocationManager.fuelConditionText(observation.fuelCondition),
        currentValueTextPrevious: LocationManager.fuelConditionText(observation.fuelConditionPrevious),
        prevValueText: `${observation.fuelConditionPrevious ?? null}${
          observation.fuelConditionPrevious != null ? '%' : ''
        }`,
        prevValueTime: observation?.validationTime,
        observerName,
      });
      setModalOpen(true);
      break;
    default:
      setModalSettings(null);
      setModalOpen(false);
  }
};
