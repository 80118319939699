import React, { useEffect, useState, useRef, useCallback } from 'react';

import { Link } from 'react-router-dom';
import { Style, Icon } from 'ol/style';

import { makeStyles, createStyles, useTheme } from '@material-ui/core/styles';
import {
  BottomNavigation,
  BottomNavigationAction,
  Typography,
  Container,
  Theme,
  Button,
  CircularProgress,
} from '@material-ui/core';
import { Room, FormatListBulleted, Sort, MyLocation } from '@material-ui/icons';

import { fromLonLat } from 'ol/proj';
import { View } from 'ol';

import { LocationManager } from 'models';
import { Map, MapOverlay, LocationCard, FloatingMapButton, ErrorSegment } from 'components';

import { useAppSelector, useAppDispatch } from 'hooks';
import { ObserverActions } from 'state/observer';
import { UserLocationActions } from 'state/userlocation';
import { MapCommand, ZoomToBounds, ZoomToCoords } from 'components/map/MapCommands';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    bottomNav: {
      width: '100%',
      position: 'relative',
      bottom: 0,
      backgroundColor: theme.palette.background.default,
      borderTop: '1px solid lightgrey',
    },
    container: {
      backgroundColor: theme.palette.background.default,
      minHeight: '100vh',
    },
    sortButton: {
      width: '100%',
      marginBottom: theme.spacing(2),
    },
    sortButtonText: {
      fontWeight: 'bold',
      color: theme.palette.text.secondary,
    },
    headerText: {
      fontWeight: 'bold',
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
  }),
);

const useActionButtonStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      maxWidth: '50%',
      '&$selected': {
        color: theme.palette.common.black,
      },
    },
    selected: {
      maxWidth: '50%',
      color: theme.palette.common.black,
    },
  }),
);

const sortValues: {
  field: keyof LocationManager.Location;
  desc: boolean;
  label?: string;
}[] = [
  {
    field: 'distance',
    desc: false,
  },
  {
    field: 'age',
    desc: true,
  },
  {
    field: 'name',
    desc: false,
  },
];

const rotateSort = (value: number) => (value + 1) % sortValues.length;

function FSELocationsMobile() {
  const { observer, position, auth } = useAppSelector((state) => state);
  const { locations } = observer;
  const dispatch = useAppDispatch();

  const hasPosition = position.object && position.object.coords.latitude && position.object.coords.longitude;

  const [dispatchToMap, setDispatchToMap] = useState<{ dispatch?: (command: MapCommand) => void }>({});
  const [currentBounds, setCurrentBounds] = useState<
    { minLong: number; minLat: number; maxLong: number; maxLat: number } | undefined
  >(undefined);

  const handleMapRegistration = (x: (command: MapCommand) => void) => {
    setDispatchToMap({ dispatch: x });
  };
  const mapDispatch = (command: MapCommand) => dispatchToMap.dispatch && dispatchToMap.dispatch(command);

  const getBounds = useCallback(() => {
    if (!locations.object) return undefined;
    if (locations.object.filter((x) => x.highlight === true).length) {
      return undefined;
    }
    // return the bounds of all locations
    return {
      minLong: Math.min(...locations.object?.map((x) => x.lon)),
      minLat: Math.min(...locations.object?.map((x) => x.lat)),
      maxLong: Math.max(...locations.object?.map((x) => x.lon)),
      maxLat: Math.max(...locations.object?.map((x) => x.lat)),
    };
  }, [locations.object]);

  const view = new View({ center: fromLonLat([130, -28]), zoom: 5 });
  const containerRef = useRef<HTMLDivElement>(null);
  const { current } = containerRef;

  useEffect(() => {
    if (locations.status === 'finished') {
      if (!locations.object) return;
      if (window.location.pathname.endsWith('map')) {
        const bounds = getBounds();
        if (bounds && JSON.stringify(bounds) !== JSON.stringify(currentBounds)) {
          mapDispatch(new ZoomToBounds(bounds));
          setCurrentBounds(bounds);
        }
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locations.status, current]);

  useEffect(() => {
    if (auth.status === 'finished') dispatch(ObserverActions.getLocations());
  }, [dispatch, auth.status]);

  useEffect(() => {
    if (position.status === 'idle') dispatch(UserLocationActions.getPosition());
  }, [dispatch, position.status]);

  useEffect(() => {
    if (position.status === 'finished') dispatch(ObserverActions.updateDistances());
  }, [position.status, dispatch]);

  useEffect(() => {
    // Update users position every 2.5 seconds
    const intervalPosition = setInterval(() => dispatch(UserLocationActions.getPosition()), 2500);
    setTimeout(() => dispatch(ObserverActions.updateDistances()), 250);

    // Check the locations list every 60 seconds
    const intervalLocation = setInterval(() => dispatch(ObserverActions.getLocations()), 60000);

    return () => {
      clearInterval(intervalPosition);
      clearInterval(intervalLocation);
    };
  }, [dispatch]);

  const classes = useStyles();
  const theme = useTheme();
  const actionButtonStyles = useActionButtonStyles();
  const initValue = window.location.pathname === '/observer/locations/list' ? 0 : 1;
  const [value, setValue] = React.useState(initValue);
  const [sortByIdx, setSortByIdx] = React.useState(position.object?.coords.latitude != null ? 2 : 0);
  const sortBy = sortValues[rotateSort(sortByIdx)];

  const sortFn = (a: LocationManager.Location, b: LocationManager.Location) => {
    // Typescript is complaining that a[sortBy.field] may be null and this can
    // be the case however it won't cause an issue

    if (a[sortBy.field] == null && b[sortBy.field] == null) return 0;
    if (sortBy.desc) {
      if (a[sortBy.field] == null) return -1;
      if (b[sortBy.field] == null) return 1;
      // @ts-ignore
      return a[sortBy.field] < b[sortBy.field] ? 1 : -1;
    }
    if (a[sortBy.field] == null) return 1;
    if (b[sortBy.field] == null) return -1;
    // @ts-ignore
    return a[sortBy.field] > b[sortBy.field] ? 1 : -1;
  };

  // Counts up locations that have no validated observation today where most recent obersvation is validated and locations without any observations.
  const observerCount = observer.locations.object?.reduce<number>(
    (acc, loc) =>
      acc + (loc.observations[0]?.validated && !loc.observations[0]?.validatedToday ? 1 : 0) + +!loc.observations[0],
    0,
  );

  const locationsLayerSettings = {
    id: 'locations',
    dataSource: ['observer', 'locations', 'object'],
    toFeatures: LocationManager.toFeatures,
    style: new Style({
      image: new Icon({
        src: '/map/marker-light.png',
      }),
    }),
  };

  const showList = window.location.pathname !== '/observer/locations/map';

  const locationCards =
    locations.object &&
    locations.object
      .slice()
      .sort(sortFn)
      .map(({ id, name, distance, age, highlight, lon, lat, observations }) => (
        <LocationCard
          id={id}
          key={`list-${id}`}
          lat={lat}
          lon={lon}
          name={name}
          distance={distance}
          age={age}
          highlight={highlight}
          observations={observations}
          onClick={() => {
            dispatch(ObserverActions.setHighlightLocation(id));
            mapDispatch(new ZoomToCoords([lon, lat], 15));
          }}
        />
      ));

  // TODO: Find a way to avoid writing both of these, difference is just the style that needs to be applied
  const locationCardsMap =
    locations.object &&
    locations.object
      .slice()
      .sort(sortFn)
      .map(({ id, name, distance, age, highlight, lon, lat, observations }) => (
        <LocationCard
          id={id}
          key={`map-${id}`}
          lat={lat}
          lon={lon}
          name={name}
          distance={distance}
          age={age}
          highlight={highlight}
          observations={observations}
          display="inline-block"
          onClick={() => {
            dispatch(ObserverActions.setHighlightLocation(id));
            mapDispatch(new ZoomToCoords([lon, lat], 15));
          }}
          style={{
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(1),
          }}
        />
      ));

  return (
    <div ref={containerRef} style={{ height: '100%', display: 'grid', gridTemplateRows: '1fr auto' }}>
      <div
        style={{
          height: `100%`,
          overflowY: 'auto',
          display: showList ? 'block' : 'none',
        }}
      >
        <Container className={classes.container}>
          <Typography variant="h5" color="textPrimary" className={classes.headerText}>
            {observerCount ?? 0} locations awaiting observation
          </Typography>
          <Button variant="outlined" className={classes.sortButton} onClick={() => setSortByIdx(rotateSort(sortByIdx))}>
            <Sort className={classes.sortButtonText} />
            <Typography className={classes.sortButtonText}>Sort by {sortBy.label || sortBy.field}</Typography>
          </Button>
          {locations.status === 'loading' && (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <CircularProgress aria-valuetext="loading" />
            </div>
          )}
          {locations.status === 'error' && locations.error && (
            <ErrorSegment
              error={locations.error}
              text="Failed To load Locations"
              onRetry={() => dispatch(ObserverActions.getLocations())}
            />
          )}

          {locationCards}
        </Container>
      </div>
      <div
        style={{
          position: 'relative',
          top: 0,
          left: 0,
          width: `100vw`,
          height: `100%`,
          display: showList ? 'none' : 'block',
        }}
      >
        <Map
          registerMapCommand={handleMapRegistration}
          featureLayers={[locationsLayerSettings]}
          view={view}
          showUser
          basemap="OSM"
          shouldDisplay={!showList}
        >
          <MapOverlay
            top="calc(100% - 200px)"
            left="0%"
            width="100%"
            height="190px"
            opacity={0.0}
            style={{
              overflowX: 'auto',
              whiteSpace: 'nowrap',
              boxShadow: '0px 0px 0px 0px rgba(0,0,0,0)',
            }}
          >
            {locationCardsMap}
          </MapOverlay>
          <MapOverlay
            top="calc(100% - 260px)"
            left="calc(100% - 55px)"
            width="45px"
            height="45px"
            opacity={1}
            style={{
              borderRadius: 50,
              boxShadow: '0px 0px 0px 0px rgba(0,0,0,0)',
            }}
          >
            <FloatingMapButton
              onClick={() => {
                if (position.object && hasPosition) {
                  mapDispatch(
                    new ZoomToCoords([position.object.coords.longitude, position.object.coords.latitude], 15),
                  );
                }
              }}
            >
              <MyLocation
                style={{
                  color: hasPosition ? 'black' : 'lightgrey',
                }}
              />
            </FloatingMapButton>
          </MapOverlay>
        </Map>
      </div>

      <BottomNavigation
        value={value}
        onChange={(event, newValue) => {
          setValue(newValue);
        }}
        showLabels
        className={classes.bottomNav}
      >
        <BottomNavigationAction
          classes={actionButtonStyles}
          label="List"
          component={Link}
          to="/observer/locations/list"
          icon={<FormatListBulleted />}
        />
        <BottomNavigationAction
          classes={actionButtonStyles}
          label="Map"
          component={Link}
          to="/observer/locations/map"
          icon={<Room />}
        />
      </BottomNavigation>
    </div>
  );
}

export default FSELocationsMobile;
