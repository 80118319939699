import React, { useEffect, useState } from 'react';

import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Button, Theme, Typography } from '@material-ui/core';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import { getContinuityText, getfuelConditionText } from 'utils';
import { LocationManager } from 'models';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      width: '100%',
      borderWidth: '1px',
      borderColor: '#ddd',
      borderRadius: '16px',
      marginTop: '15px',
      backgroundColor: '#fff',
      padding: '8px',
    },
    buttonLight: {
      // height: '50px',
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      fontWeight: 'bold',
      // backgroundColor: theme.palette.common.neutralLight,
      color: theme.palette.common.neutralDark,
      marginBottom: theme.spacing(1),
      marginTop: theme.spacing(2),
      '&:hover': {
        backgroundColor: theme.palette.common.neutralXXLight,
      },
    },
    container: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
    header: {
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '10px',
    },
    // title: {
    //   // fontSize: "0.rem",
    //   // fontWeight: 'bold',
    //   // lineHeight: "24px",
    //   // letterSpacing: "0.5px",
    // },
    icon: {
      display: 'flex',
      alignItems: 'center',
    },
    list: {
      paddingHorizontal: '15px',
      paddingBottom: '5px',
      paddingTop: '5px',
      marginVertical: '10px',
      fontWeight: 'normal',
      color: theme.palette.common.black70,
    },
    item: {
      paddingVertical: '4px',
      fontSize: '16px',
    },
    dateText: {
      fontSize: '14px',
      color: 'black',
      textAlign: 'left',
      marginLeft: '10px',
      paddingVertical: '10px',
    },
    noDataText: {
      fontSize: '14px',
      color: '#888',
      textAlign: 'center',
      paddingVertical: '10px',
    },
  }),
);

interface PreviousValuesDropdownProps {
  historicObservations: LocationManager.Location.Observation[] | null;
  type: string;
}

const PreviousValuesDropdown: React.FunctionComponent<PreviousValuesDropdownProps> = ({
  historicObservations,
  type,
}) => {
  const classes = useStyles();

  // Why is this state not being initialised each time?
  const [isCollapsed, setIsCollapsed] = useState(true);

  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  // Make sure that the previous results window collapses when the step changes
  useEffect(() => {
    setIsCollapsed(true);
  }, [type]);

  const validatedObservations: LocationManager.Location.Observation[] = [];

  for (const observation of historicObservations || []) {
    if (observation.validated) {
      validatedObservations.push(observation);
    }
  }

  // Grabs the latest 4 validated observations
  // TODO: Need to check with Nikki if this is sorted by obs or validated time.
  const latestValidatedObservations =
    validatedObservations
      ?.sort((a, b) => new Date(b.time ?? 0).getTime() - new Date(a.time ?? 0).getTime())
      .slice(0, 4) ?? [];

  // grab all the values for the property that matches the type
  const mapFriendlyHistoricObservations = latestValidatedObservations?.map((observation) => {
    const date = new Date(observation.time ?? 0);

    const formattedDate = date.toLocaleDateString('en-AU', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    });

    const value =
      type === 'grassCuring'
        ? `${observation.curing !== null ? `${observation.curing}%` : 'null'}`
        : type === 'fuelCondition'
        ? `${getfuelConditionText(observation.fuelCondition)}`
        : type === 'continuity'
        ? `${getContinuityText(observation.continuous)}`
        : type === 'fuelLoad'
        ? `${observation.fuelLoad?.toFixed(1)}`
        : type === 'photoURL'
        ? observation.imageUrl
        : 'Unknown';

    return type == 'photoURL' ? [formattedDate, value] : `${formattedDate} - ${value}`;
  });

  return (
    <div className={classes.container} style={{ paddingTop: type === 'photoURL' ? '0px' : '4vh' }}>
      <Button
        variant="outlined"
        onClick={toggleCollapse}
        className={classes.buttonLight}
        style={{ height: isCollapsed ? '50px' : 'auto', alignSelf: 'center' }}
      >
        <div style={{ flexDirection: 'column' }}>
          <div className={classes.header}>
            {'Previous Results'}
            <div className={classes.icon}>{isCollapsed ? <ExpandMore /> : <ExpandLess />}</div>
          </div>

          {!isCollapsed && (
            <div className={classes.list}>
              {mapFriendlyHistoricObservations?.map((historicObservation, index) => {
                if (type === 'photoURL') {
                  return historicObservation[1] != null ? (
                    <div style={{ width: '100%', alignItems: 'center' }} key={index}>
                      <Typography variant="subtitle1" className={classes.dateText}>
                        {historicObservation[0]}
                      </Typography>
                      <img src={historicObservation[1]} alt="Previous Photo" style={{ height: '25%', width: '50%' }} />
                    </div>
                  ) : (
                    <div style={{ width: '100%', alignItems: 'center' }} key={index}>
                      <Typography variant="subtitle1" className={classes.dateText}>
                        {historicObservation[0]}
                      </Typography>

                      <div className={classes.noDataText}>No previous photo uploaded</div>
                    </div>
                  );
                } else {
                  return <div key={index}>{historicObservation}</div>;
                }
              })}
            </div>
          )}
        </div>
      </Button>
    </div>
  );
};

export default PreviousValuesDropdown;
