/* eslint-disable react/jsx-props-no-spreading */
// This is disabled so we can use the recommended approach to using react-dropzone
import React, { useState, useCallback } from 'react';
import { isMobile } from 'react-device-detect';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Theme, Button, RootRef, Paper } from '@material-ui/core';
import { useDropzone } from 'react-dropzone';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      height: '90%',
      display: 'grid',
      gridTemplateRows: '1fr auto',
    },
    button: {
      height: '50px',
      fontWeight: 'bold',
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
      marginBottom: theme.spacing(1),
      marginTop: theme.spacing(2),
      '&:hover': {
        backgroundColor: theme.palette.common.black,
      },
    },
    buttonLight: {
      height: '50px',
      fontWeight: 'bold',
      backgroundColor: theme.palette.common.neutralLight,
      color: theme.palette.common.neutralDark,
      marginBottom: theme.spacing(1),
      marginTop: theme.spacing(2),
      '&:hover': {
        backgroundColor: theme.palette.common.neutralLight,
      },
    },
    buttonRed: {
      height: '50px',
      fontWeight: 'bold',
      backgroundColor: theme.palette.common.red,
      color: theme.palette.common.white,
      marginBottom: theme.spacing(1),
      marginTop: theme.spacing(2),
      '&:hover': {
        backgroundColor: theme.palette.common.red,
      },
    },
    paper: {
      height: '50vh',
      display: 'grid',
      placeItems: 'center',
    },
    paperMobile: {
      height: '40vh',
      display: 'grid',
      placeItems: 'center',
    },
    emptyPaper: {
      height: '50vh',
      display: 'grid',
      placeItems: 'center',
      border: `2px dashed ${theme.palette.common.neutralDark}`,
    },
    emptyPaperMobile: {
      height: '40vh',
      display: 'grid',
      placeItems: 'center',
      border: `2px dashed ${theme.palette.common.neutralDark}`,
    },
    img: {
      width: '100%',
      height: '50vh',
      objectFit: 'contain',
    },
    imgMobile: {
      width: '100%',
      height: '40vh',
      objectFit: 'contain',
    },
  }),
);

interface ImageUploadProps {
  onChange: (value: string | null) => void;
  style?: React.CSSProperties;
  initialValue?: string;
  initialURL?: string;
}

const ImageUpload: React.FunctionComponent<ImageUploadProps> = ({ initialValue, style, onChange, initialURL }) => {
  const classes = useStyles();

  const [imageSrc, setImageSrc] = useState<string | null>(initialValue ?? initialURL ?? null);

  const paperClass = isMobile ? classes.paperMobile : classes.paper;
  const emptyPaperClass = isMobile ? classes.emptyPaperMobile : classes.emptyPaper;
  const imgClass = isMobile ? classes.imgMobile : classes.img;

  const onDrop = useCallback(
    (acceptedFiles: any) => {
      acceptedFiles.forEach((file: any) => {
        const reader = new FileReader();

        reader.onabort = () => console.log('file reading was aborted');
        reader.onerror = () => console.log('file reading has failed');
        reader.onload = () => {
          setImageSrc(reader.result as string);
          onChange(reader.result as string);
        };
        reader.readAsDataURL(file);
      });
    },
    [onChange],
  );
  const { getRootProps, getInputProps } = useDropzone({ onDrop });
  const { ref, ...rootProps } = getRootProps({ className: 'dropzone' });
  return (
    <div className={classes.root}>
      <Paper className={imageSrc ? paperClass : emptyPaperClass} style={style}>
        {imageSrc ? <img src={imageSrc} alt="Uploaded" className={imgClass} /> : undefined}
      </Paper>
      {/* @ts-ignore */}
      <RootRef rootRef={ref}>
        <div {...rootProps}>
          <input {...getInputProps()} />
          <Button variant="contained" fullWidth className={imageSrc ? classes.buttonLight : classes.button}>
            {imageSrc ? 'Use another Photo' : 'Add Photo'}
          </Button>
        </div>
      </RootRef>
      {imageSrc && (
        <Button
          variant="contained"
          fullWidth
          className={classes.buttonRed}
          onClick={() => {
            setImageSrc(null);
          }}
        >
          Remove Photo
        </Button>
      )}
    </div>
  );
};

ImageUpload.displayName = 'ImageUpload';
export default ImageUpload;
